<template>
      <div class="flex-col line-tabs">
        <div class="flex-col group_9">
          <!-- 信息列表 -->
          <div class="list_2">
            <template v-for="item in list">
              <div class="list-item_2 flex-row" :key="item.title">
                <span class="text_15">{{item.title}}</span>
                <span class="text_17">{{item.text}}</span>
              </div>
            </template>
          </div>
        </div>
      </div>
</template>

<script>
import {info} from '@/api/Property/Elevator/all'
export default {
  data() {
    return {
      
      list: [
        { title: '物业公司', text: '' },
        { title: '电梯名称', text: '' },
        { title: '电梯位置', text: '' },
        { title: '维保公司', text: ''},
        { title: '维保人员', text: '' },
      ],
      curId: 0,
      curId1: 0
    }
  },
  methods: {

  },
  mounted(){
    info({id:this.$route.params.id}).then(res=>{
      console.log(res);
      this.list[0].text=res.data.Data.PropertyName
      this.list[1].text=res.data.Data.type
      this.list[2].text=res.data.Data.CompanyName
      this.list[3].text=res.data.Data.address
      this.list[4].text=res.data.Data.RealName
    })
  },
}

</script>

<style scoped>
@import '../../../assets/common.css';
.list-item {
  color: rgb(180, 180, 180);
  font-size: 0.88rem;
  line-height: 0.88rem;
  white-space: nowrap;
  padding-top: 0.38rem;
}
.active {
  color: rgb(0, 0, 0);
  font-size: 1.25rem;
  line-height: 1.19rem;
  white-space: nowrap;
  padding-top: 0;
}
.list-item_1 {
  padding: 0.54rem 0;
  font-size: 0.75rem;
  line-height: 0.75rem;
  white-space: nowrap;
  border: 1px solid #f0f2f0;
  border-radius: 1rem;
  width: 4.5rem;
  height: 2rem;
}
.list-item_1:not(:first-of-type) {
  margin-left: 0.5rem;
}
.list-item_10 {
  padding: 0.54rem 0;
  color: rgb(30, 200, 64);
  font-size: 0.75rem;
  line-height: 0.75rem;
  white-space: nowrap;
  border: 1px solid #dffae5;
  background: #dffae5;
  border-radius: 1rem;
  width: 4.5rem;
  height: 2rem;
}
.list-item_2 {
  padding: 1.19rem 0.063rem;
}
.text_15 {
  color: rgb(179, 179, 178);
  font-size: 0.75rem;
  line-height: 0.75rem;
  white-space: nowrap;
  display: inline-block;
  width: 6rem;
}
.text_17 {
  margin-left: 1.13rem;
  margin-top: 0.13rem;
  color: rgb(179, 179, 178);
  font-size: 0.75rem;
  line-height: 0.63rem;
  white-space: nowrap;
}
.page {
  padding-top: 0.063rem;
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.section_1 {
  padding: 0.94rem 0.81rem 0.88rem;
  color: rgb(0, 0, 0);
  font-size: 1rem;
  line-height: 0.94rem;
  white-space: nowrap;
  background-color: rgb(237, 237, 237);
}
.group_1 {
  overflow-y: auto;
  position: relative;
}
.image_1 {
  margin-right: 0.25rem;
  align-self: center;
  width: 1.13rem;
  height: 0.25rem;
}
.section_2 {
  padding: 1.75rem 0 3.75rem 1.38rem;
  background: url(../../../assets/imgbg3.png) center top no-repeat;
  background-size: 100% 100%;
}
.line-tabs {
  overflow-x: hidden;
  border-radius: 1rem;
  background: #fff;
  margin-top: -2.4rem;
}
.image {
  margin-bottom: 0.13rem;
  width: 0.81rem;
  height: 0.81rem;
}
.text {
  margin-left: 0.75rem;
}
.group_2 {
  padding: 0 0.13rem;
}
.group_4 {
  margin-top: 1rem;
}
.list {
  padding: 1.88rem 0;
}
.group_9 {
  padding: 0 1.25rem 0 1.25rem;
  padding-bottom: 5.25rem;
  overflow-x: hidden;
}
.text_1 {
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
  line-height: 1.44rem;
  white-space: nowrap;
}
.group_3 {
  margin-right: 0.44rem;
  color: rgb(255, 255, 255);
  font-size: 0.75rem;
  line-height: 0.75rem;
  white-space: nowrap;
}
.group_5 {
  flex: 1 1 auto;
}
.list-item:not(:first-of-type) {
  margin-left: 1.25rem;
}
.list_1 {
  overflow-x: scroll;
  padding-bottom: 0.8rem;
}
.list_2 {
  margin-top: 0.63rem;
  height: 47.81rem;
  position: relative;
}
.image_2 {
  display: inline-block;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background: #4cd964;
  border: 1px solid #fff;
  margin-top: 0.1rem;
}
.text_2 {
  margin-left: 0.31rem;
}
.text_3 {
  margin-left: 0.19rem;
  color: rgb(255, 255, 255);
  font-size: 0.75rem;
  line-height: 0.75rem;
  white-space: nowrap;
}
.group_6 {
  margin-top: 1rem;
  padding: 0 0.063rem;
  color: rgb(255, 255, 255);
  font-size: 0.75rem;
  line-height: 0.81rem;
  white-space: nowrap;
}
.group_7 {
  margin-top: 0.81rem;
  color: rgb(255, 255, 255);
  font-size: 0.75rem;
  line-height: 0.81rem;
  white-space: nowrap;
}
.image_3 {
  width: 0.75rem;
  height: 0.94rem;
}
.text_4 {
  margin-left: 0.31rem;
  margin-top: 0.13rem;
}
.image_4 {
  width: 0.81rem;
  height: 0.88rem;
}
.text_5 {
  margin-left: 0.31rem;
}
</style>

